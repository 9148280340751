@import url(https://fonts.googleapis.com/css?family=Dancing+Script|Yesteryear);

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body{
  background-image: url(/static/media/bg.dd7b1916.jpg);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  margin:0;
  padding: 0;
}

@media screen and (max-width: 470px){

   body{
     background-image: url(/static/media/bg.dd7b1916.jpg);
     background-size: cover;
     background-repeat: no-repeat;
     margin:0;
     padding: 0;

   }
}

.logoJewgeny{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: white;
  font-size: 70px;
  border: 3px solid white;
  background: grey;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: 20px auto;
  font-family: 'Yesteryear', cursive;
  text-shadow: #CCC 1px 0 15px;
}

.logoFont{
  font-family: 'Dancing Script', cursive;
  font-size: 50px;
  text-align: center;
  margin: 0 auto;
  color: #909800;
}

.welcome{
  color:#909800;
  margin-bottom: 30px !important;
}

.welcomeContainer{
  width: 50%;
  position: absolute;
  top: 45%;
  left: 30%;
  z-index: -1;
}

.toPrortfolioButton{
  background: #909800;
  color: white;
  font-size: 25px;
  padding: 10px 5px;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  border: 2px solid transparent;
}

.toPrortfolioButton:hover{
  background: white;
  color: #909800;
  border: 2px solid #909800;
  text-decoration: none;
}



@media screen and (max-width: 500px) {
  .welcomeContainer{
    background: black;
    width: 70%;
    opacity: 0.8;
    padding: 5vw;
    position: absolute;
    display: block;
    left: 15%;
    top: 40%;
    z-index: -1;
  }

  .toPrortfolioButton{
    background: #909800;
    color: white;
    font-size: 18px;
    padding: 10px 5px;
  }
}

.navBarOpacity{
  background: rgba(0, 0, 0, 0.85);
  height: 100% !important;
  width: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: absolute;
  right: 0;
  overflow: hidden;
}

.position{
  position: relative;
}

.liMobil{
  color: white;
}

.liMobil:hover{
  color: white;
}

.navListUnderline{
  border-bottom: 10px solid transparent !important;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.navListUnderline:hover{
  border-bottom: 10px solid #909800 !important;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.activeLink{
  /*border-bottom: 10px solid #909800 !important;*/
  color: #909800 !important;
}


.fade-enter{
  opacity: 0;
}

.fade-enter-active{
  opacity: 1;
  -webkit-animation: fadeInRight 1s ease-in;
          animation: fadeInRight 1s ease-in;
}

.fade-exit{
  opacity: 1;
  -webkit-animation: fadeOutRight 1s ease-out;
          animation: fadeOutRight 1s ease-out;
}

.fade-exit-active{
  opacity: 0;
}

.menuMobil{
  padding: 20px !important;

}

.menuMobilIcon{
 z-index: 1;
}

@media screen and (max-width: 470px) {
    .menuMobil{
      padding: 10px !important;
      /*position: absolute;*/

    }

    .liMobil{
      font-size: 25px !important
    }

    .menuMobilIcon{
      background: rgba(0,0,0,0.8);
      padding: 1px 5px;
      cursor: pointer !important;
    }

    .navListUnderline{
      border-bottom: 5px solid transparent !important;
      -webkit-transition: all 1s;
      transition: all 1s;
    }

    .navListUnderline:hover{
      border-bottom: 5px solid #909800 !important;
      -webkit-transition: all 1s;
      transition: all 1s;
    }
}

.cup{
  background: url(/static/media/cup.ed89c497.png);
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 2vw;
  width: 20vw;
  height: 20vw;
  position: absolute;
  left: 35%;
  z-index: -1;
}

img.smokeEffect{
  position: relative;
  top: 40%;
  left: 20%;
  width: 30vw;
  -webkit-animation-name: makeSmoke;
          animation-name: makeSmoke;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-direction: normal;
          animation-direction: normal;
  -webkit-transition: all 1.5s;
  transition: all 1.5s;
  opacity: 0;

}

@-webkit-keyframes makeSmoke {
  0%{
                 -webkit-transform:
                 translateX(5%) rotate(45deg);
                         transform:
                 translateX(5%) rotate(45deg);
                 -webkit-filter: blur(3px);
                         filter: blur(3px);
                 opacity: 0;
                 width: 10vw}

  25%{
                 opacity: 0.2;
                 -webkit-filter: drop-shadow(4px 4px 4px #000);
                         filter: drop-shadow(4px 4px 4px #000);}
  50%{
                  -webkit-transform:
                  translateX(0%) rotate(45deg);
                          transform:
                  translateX(0%) rotate(45deg);
                  opacity: 0.3;
                  -webkit-filter: drop-shadow(4px 4px 4px #000);
                          filter: drop-shadow(4px 4px 4px #000);
                  width:20vw; }
  100%{
                  -webkit-transform: translateX(4%) rotate(45deg);
                          transform: translateX(4%) rotate(45deg);
                  opacity: 0;
                  width:30vw; }
}

@keyframes makeSmoke {
  0%{
                 -webkit-transform:
                 translateX(5%) rotate(45deg);
                         transform:
                 translateX(5%) rotate(45deg);
                 -webkit-filter: blur(3px);
                         filter: blur(3px);
                 opacity: 0;
                 width: 10vw}

  25%{
                 opacity: 0.2;
                 -webkit-filter: drop-shadow(4px 4px 4px #000);
                         filter: drop-shadow(4px 4px 4px #000);}
  50%{
                  -webkit-transform:
                  translateX(0%) rotate(45deg);
                          transform:
                  translateX(0%) rotate(45deg);
                  opacity: 0.3;
                  -webkit-filter: drop-shadow(4px 4px 4px #000);
                          filter: drop-shadow(4px 4px 4px #000);
                  width:20vw; }
  100%{
                  -webkit-transform: translateX(4%) rotate(45deg);
                          transform: translateX(4%) rotate(45deg);
                  opacity: 0;
                  width:30vw; }
}


@media screen and (max-width: 470px){
   .cup{
     position: absolute;
     top: 5%;
     width: 50vw;
     height: 50vw;
     overflow: hidden;
   }

   img.smokeEffect{
     position: relative;
     top: 40%;
     left: 20%;
     width: 50vw;
     -webkit-animation-name: makeSmoke;
             animation-name: makeSmoke;
     -webkit-animation-duration: 10s;
             animation-duration: 10s;
     -webkit-animation-timing-function: linear;
             animation-timing-function: linear;
     -webkit-animation-delay: 0.5s;
             animation-delay: 0.5s;
     -webkit-animation-iteration-count: infinite;
             animation-iteration-count: infinite;
     -webkit-animation-direction: normal;
             animation-direction: normal;
     -webkit-transition: all 1.5s;
     transition: all 1.5s;
     opacity: 0;

   }

   @-webkit-keyframes makeSmoke {
     0%{
                    -webkit-transform:
                    translateX(5%) rotate(45deg);
                            transform:
                    translateX(5%) rotate(45deg);
                    -webkit-filter: blur(3px);
                            filter: blur(3px);
                    opacity: 0;
                    width: 20vw}

     25%{
                    opacity: 0.2;
                    -webkit-filter: drop-shadow(5px 5px 5px #000);
                            filter: drop-shadow(5px 5px 5px #000);}
     50%{
                     -webkit-transform:
                     translateX(4%) rotate(45deg);
                             transform:
                     translateX(4%) rotate(45deg);
                     opacity: 0.4;
                     -webkit-filter: drop-shadow(5px 5px 5px #000);
                             filter: drop-shadow(5px 5px 5px #000);
                     width:40vw; }
     100%{
                     -webkit-transform: translateX(4%) rotate(45deg);
                             transform: translateX(4%) rotate(45deg);
                     opacity: 0;
                     width:60vw; }
   }

   @keyframes makeSmoke {
     0%{
                    -webkit-transform:
                    translateX(5%) rotate(45deg);
                            transform:
                    translateX(5%) rotate(45deg);
                    -webkit-filter: blur(3px);
                            filter: blur(3px);
                    opacity: 0;
                    width: 20vw}

     25%{
                    opacity: 0.2;
                    -webkit-filter: drop-shadow(5px 5px 5px #000);
                            filter: drop-shadow(5px 5px 5px #000);}
     50%{
                     -webkit-transform:
                     translateX(4%) rotate(45deg);
                             transform:
                     translateX(4%) rotate(45deg);
                     opacity: 0.4;
                     -webkit-filter: drop-shadow(5px 5px 5px #000);
                             filter: drop-shadow(5px 5px 5px #000);
                     width:40vw; }
     100%{
                     -webkit-transform: translateX(4%) rotate(45deg);
                             transform: translateX(4%) rotate(45deg);
                     opacity: 0;
                     width:60vw; }
   }
}

.wrapper{
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  height: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: scroll;
}

.cvButton{
  padding: 5px 10px;
  margin: 20px 0;
  border: .5px solid #909800;
  background: transparent;
  color: white;
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.cvButton:hover{
 opacity: .8;
 color: #909800;
}

.cvButton:focus{
  outline: none;
 }

.wrapperUp{
  -webkit-animation-name: goUP;
          animation-name: goUP;
  -webkit-animation-duration: .5s;
          animation-duration: .5s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.pContainer{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding-right: 1vw;
}

::-webkit-scrollbar {
  width: 7px !important;
}

::-webkit-scrollbar-corner {
  background: rgba(0,0,0,0) !important; }

::-webkit-scrollbar-thumb {
  background-color: rgba(144, 152, 0, .5) !important;
}


.contentWrapper{
  margin-top: 5vw;
  display: -webkit-flex;
  display: flex;
  padding: 0 15vw;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.meImg{
  border-radius: 50%;
  border: 5px solid #ccc;
  margin-bottom: 2vw;
  margin-left: 3vw;
}

@-webkit-keyframes goUP{
  0%{height: 0;}
  100%{height: 100%;}
}

@keyframes goUP{
  0%{height: 0;}
  100%{height: 100%;}
}

.wrapperAboutMe{
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: column;
           flex-direction: column;
   -webkit-align-items: center;
           align-items: center;
   z-index: 999;
   opacity: 1;
}

/*CSSTransition*/

/*end CSSTransition*/


@-webkit-keyframes makeImg{
  0%{width: 0; height: 0}
  100%{width: 25vw; height: 26vw}
}


@keyframes makeImg{
  0%{width: 0; height: 0}
  100%{width: 25vw; height: 26vw}
}

.showImg{
  -webkit-animation: makeImg;
          animation: makeImg;
  -webkit-animation-duration: .5s;
          animation-duration: .5s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transition: all 1s;
  transition: all 1s;
}


h2{
  color: white;
  margin-top: 3vw !important;
  color: #909800;
}

p{
  width: 60%;
  color: white;
}

.hrAboutMe{
  height: 0.1px !important;
  width: 40%;
  background-color: #ccc;
}

.link{
  color: #909800;
}




@media screen and (max-width: 500px){
 
  .contentWrapper{
    margin-top: 2vw;
    display: -webkit-flex !important;
    display: flex !important;
    padding: 0vw !important;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;

  }

  .pContainer p{
    margin-top: 8vw;
    height: 130vw;
    width: 85%;
  }

  .meImg{
    margin-top: 5vw;
    border-radius: 50%;
    border: 3px solid #ccc;
    margin-bottom: 0;
    width: 45vw;
    height: 47vw;
    margin-left: 0vw;
  }

  .hrAboutMe{
    width: 80%;
  }


  @-webkit-keyframes makeImg{
    0%{width: 0; height: 0}
    100%{width: 45vw; height: 47vw}
  }


  @keyframes makeImg{
    0%{width: 0; height: 0}
    100%{width: 45vw; height: 47vw}
  }

  .showImg{
    -webkit-animation: makeImg;
            animation: makeImg;
    -webkit-animation-duration: .5s;
            animation-duration: .5s;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-transition: all 1s;
    transition: all 1s;
  }

}

.wholeWrapperCv{
    height: 500px;
    overflow: scroll;
}

.blockWrapper{
    display: -webkit-flex;
    display: flex;
    margin: 0px 0;
    width: 90%;
    margin-top: 3px;
}


.titelCV{
    color: #909800;
    font-size: 18px;
    border-bottom: 1.5px solid #909800;
    padding-bottom: 5px;
    margin: 25px 0;
    width: 80%;
}


.dateArrowWrapper{
  display: -webkit-flex;
  display: flex;
  width: 200px;
}

.arrowCV{
    font-size: 20px;
    color: #909800;
    margin-top: -5px !important;   
}

.date{
    font-size: 14px;
    color: black;
}

.descWrapper{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

.titelDesc{
    font-weight: bold;
    font-size: 14px;
    color: black;
    margin-bottom: 5px;
}

.desc{
    font-size: 14px;
    color: black;
}

@media (max-width: 800px){
    .blockWrapper{
        display: block;
        width: 500px;
    }

    .wholeWrapperCv{
        height: 100%;
        overflow-x: hidden;
    }

    .dateArrowWrapper{
        width: 200px;
      }

      .descWrapper{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start;
        width: 100%;
        margin-left: 20px;
    }

}

.wrapper{
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  height:100vh !important;
  width: 100%;
  z-index: -1;
  overflow: scroll;
}

.wrapperUp{
  -webkit-animation-name: goUP;
          animation-name: goUP;
  -webkit-animation-duration: .5s;
          animation-duration: .5s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transition: all 1s;
  transition: all 1s;
}

@-webkit-keyframes goUP{
  0%{height: 0;}
  100%{height: 100%;}
}

@keyframes goUP{
  0%{height: 0;}
  100%{height: 100%;}
}

.iconSkills{
  margin-bottom: 1px;
  font-size: 2vw;
  color: #909800;
}

.icons{
  margin-right: 3px;
}

.react{
  margin-right: 3px;
}

.wrapperKompotenzen{
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: column;
           flex-direction: column;
   -webkit-align-items: center;
           align-items: center;
   height:100vh !important;
}

/*CSSTransition*/

/*end CSSTransition*/


.progressWrapper{
  width: 50vw;
  margin: 3vw 0;
}

.wrapperSkills{
  margin-top: 3vw;
  width: 50vw;
  margin-bottom: 2vw;
}

.pProgress{
margin: 0 1vw;
}


.progressState{
  height: 2vw;
  background: #909800 !important;
}

h2{
  color: white;
  margin-top: 3vw !important;
  color: #909800;
}

p{
  width: 60%;
  color: white;
}

.hrKompotenzen{
  margin: 1vw auto !important;
  height: 0.01px;
  width: 50vw !important;
  background-color: #ccc;
}

.link{
  color: #909800;
}


.progressColor{
  color: #909800 !important;
}


.progress   {background: rgba(204, 204, 204, 1) !important;
            border: 0px solid rgba(144, 152, 0, 1) !important;
            border-radius: 4px; height: 20px; }
.progress-bar-custom {background: rgba(144, 152, 0, 1) !important;}



@media screen and (max-width: 470px){
  .progressWrapper{
     margin-top: 7vw;
     margin-left: 3vw;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
             flex-direction: column;
     -webkit-align-items: center;
             align-items: center;
     width: 90vw;
  }

  .progressState{
    height: 5vw !important;
  }

  .iconSkills{
    margin-bottom: 1px;
    font-size: 5vw;
    color: #909800;
  }

  .wrapperSkills{
    margin-bottom: 5vw;
    width: 70vw;
  }

}

.wrapperPortfolio{
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: scroll;
  z-index: -1;

}


.containerProjects{
  margin: 0 auto !important;
  padding: 0vw !important;
  padding-left: 5vw !important;

}


.buttonProjekte{
  border: 1px solid white;
  text-decoration: none !important;
  background: none;
  color: white;
  padding: .5vw 1vw;
  margin: 0 1vw;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.buttonProjekte:hover{
   border: 1px solid #909800;
   color: #909800 !important;
}

.link{
  text-decoration: none !important;
}

.link:hover{
  color: #909800;
}

.singleProduct{
  width: 25vw;
  height: 20vw;
}

.wrapperProjekt{
  margin-left: 5vw;
}

.h3SingleProduct{
  color: white;
}

.wrapperSingleProduct{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;

}


.hrProjects{
  border: .5px solid white;
  width: 100%;
  float: left;
  margin-top: 0vw;
}

.backToSelection{
  color: #909800;
  font-size: 25px;
  padding-left: 5px;
  width: 210px;
  border: 1px solid #909800;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;

}

.backToSelection:hover{
  color: white;
  text-decoration: none;
  opacity: .8;
  
}

.wrapperCarusal{
  /*border: 1px solid white;*/
  width: 350px;
  height: auto;
  overflow: hidden;
}

.imgPortfolio{
   width: 350px !important;
   -webkit-transition: all 1s;
   transition: all 1s;
   cursor: pointer;
   border: 3px solid transparent;
}

.imgPortfolio:hover{
   -webkit-filter:grayscale(100%);
           filter:grayscale(100%);
   border: 3px solid #909800 !important;
}

.arrow{
  font-size: 2.5vw;
  color: #909800;
  -webkit-transition: all 1s;
  transition: all 1s;
  cursor: pointer;
}

.arrow:hover{
  opacity: .8;
}

.iconToWebsite {
  margin-left: 7px;
  margin-bottom: 3px;
  font-size: 25px;
}


@media only screen and (max-width: 600px){
  .wrapperSingleProduct{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .imgPortfolio{
     width: 280px !important;

  }

  .wrapperCarusal{
    width: 280px;
  }

  .arrow{
    font-size: 8vw;
  }


  .singleProduct{
    width: 65vw;
    height: 60vw;
    margin: 0 auto;
    margin-top: -2vw;
    margin-bottom: 3vw;
  }

  .wrapperProjekt{
    padding: 5vw 4vw!important;

  }


  .containerProjects{
    padding: 0 !important;
    padding-left: 3vw !important;
    margin: 0 6vw !important;
  }

  .buttonProjekte{
     font-size: 4vw !important;
     padding: 1vw 2vw !important;
     margin: 0vw 2vw !important;
  }
}

.activeSelectionWrapper{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.projectsSelectonWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 15px;
}

.boxProjekte{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid white;
  text-decoration: none !important;
  background: none;
  color: white;
  padding: 5px;
  margin: 0 1vw;
  -webkit-transition: all 1s;
  transition: all 1s;
  width: 190px; 
}

.boxProjekte:hover{
   border: 1px solid #909800;
   color: #909800 !important;
}

.textProjectsSelecton {
  width: 120px;
  text-align: center;
  margin-top: 15px;
  border-bottom: 2px solid transparent;
  -webkit-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

.iconProjectsSelection {
  font-size: 25px;
}

.activeSelection {
  border: 1px solid #909800;
}

.activeSelection .iconProjectsSelection {
  color: #909800;
}

.activeSelection .textProjectsSelecton {
  border-bottom: 2px solid #909800;
}

.toggleMenu {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: 1px solid #909800;
  width: 150px;
  margin: 0 auto;
  padding: 5px;
  display: none;
}

.selectionMobile{
  margin: 0 !important;
  padding: 0 !important;
  margin-bottom: 15px;
  font-size: 18px;
}

.toggleSign {
  margin: 0;
  margin-left: 10px;
  color: #909800;
  cursor: pointer;
  font-size: 25px;
}

@media (max-width: 500px){
  .activeSelectionWrapper{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    margin-bottom: -40px;
  }


  .activeSelectionWrapper.showActiveSelection{
    max-height: 350px;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
  }

  .activeSelectionWrapper.closeSelectionWrapper{
    max-height: 0;
  }



  .boxProjekte{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    border: none;
    text-decoration: none !important;
    background: none;
    color: white;
    padding: 5px;
    margin: 5px !important;
    -webkit-transition: all 1s;
    transition: all 1s;
    height: 80px !important; 
  }

  .activeSelection {
    border: none !important;
  }

  .toggleMenu {
    width: 200px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    padding-bottom: 10px !important;
    margin-bottom: 10px;
  }

  .selectionMobile{
    padding: 0 !important;
    text-align: center;
  }
  
}
.wrapperContact{
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: center !important;
          justify-content: center !important;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  overflow: scroll;
}

.wrapperUp{
  -webkit-animation-name: goUP;
          animation-name: goUP;
  -webkit-animation-duration: .5s;
          animation-duration: .5s;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-transition: all 1s;
  transition: all 1s;
}

.ulWrapper{
  margin-left: 3vw;
  color: white;
}

.icon{
  margin-bottom: 2px;
}

.liContact{
  font-size: 2vw;
  list-style-type: none;
  padding: auto 0 !important;
}

.liContact::before {
  content: "\2022";
  margin-right: 1vw;
  font-size: 2.5vw;
  color: #909800;
  width: 3vw;
}



.iframe
{
	border: 3px solid #ccc;
	border-radius: 50%;
	position: relative;
	overflow: hidden;
	width: 25vw;
	height: 25vw;
}
.iframe iframe
{
  width: 25vw;
	height: 25vw;
}

@-webkit-keyframes goUP{
  0%{height: 0;}
  100%{height: 100%;}
}

@keyframes goUP{
  0%{height: 0;}
  100%{height: 100%;}
}


.contactWrapper{
   margin-top: 5vw;
   display: -webkit-flex;
   display: flex;
   -webkit-align-items: center;
           align-items: center;

}



.hrKompotenzen{
  margin: 1vw auto !important;
  height: 0.01px;
  width: 50vw !important;
  background-color: #ccc;
}





@media screen and (max-width: 600px){

  .contactWrapper{
     margin-top: 5vw;
     display: -webkit-flex;
     display: flex;
     -webkit-flex-direction: column;
             flex-direction: column;
  }

  .iframe
  {
  	border: 3px solid #ccc;
  	border-radius: 50%;
  	position: relative;
  	overflow: hidden;
  	width: 50vw;
  	height: 50vw;
  }
  .iframe iframe
  {
    width: 50vw;
  	height: 50vw;
  }

  .ulWrapper{
    display: block;
    margin: 5vw auto;
    margin-left: -6vw;
    color: white;
  }

  .icon{
    margin-bottom: 2px;
  }

  .liContact{
    font-size: 5vw;
    list-style-type: none;
    padding: auto 0 !important;
  }

  .liContact::before {
    content: "\2022";
    margin-right: 3vw;
    font-size: 7vw;
    color: #909800;
    width: 3vw;
  }

}

