.wrapperContact{
  display: flex !important;
  justify-content: center !important;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  overflow: scroll;
}

.wrapperUp{
  animation-name: goUP;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  transition: all 1s;
}

.ulWrapper{
  margin-left: 3vw;
  color: white;
}

.icon{
  margin-bottom: 2px;
}

.liContact{
  font-size: 2vw;
  list-style-type: none;
  padding: auto 0 !important;
}

.liContact::before {
  content: "•";
  margin-right: 1vw;
  font-size: 2.5vw;
  color: #909800;
  width: 3vw;
}



.iframe
{
	border: 3px solid #ccc;
	border-radius: 50%;
	position: relative;
	overflow: hidden;
	width: 25vw;
	height: 25vw;
}
.iframe iframe
{
  width: 25vw;
	height: 25vw;
}

@keyframes goUP{
  0%{height: 0;}
  100%{height: 100%;}
}


.contactWrapper{
   margin-top: 5vw;
   display: flex;
   align-items: center;

}



.hrKompotenzen{
  margin: 1vw auto !important;
  height: 0.01px;
  width: 50vw !important;
  background-color: #ccc;
}





@media screen and (max-width: 600px){

  .contactWrapper{
     margin-top: 5vw;
     display: flex;
     flex-direction: column;
  }

  .iframe
  {
  	border: 3px solid #ccc;
  	border-radius: 50%;
  	position: relative;
  	overflow: hidden;
  	width: 50vw;
  	height: 50vw;
  }
  .iframe iframe
  {
    width: 50vw;
  	height: 50vw;
  }

  .ulWrapper{
    display: block;
    margin: 5vw auto;
    margin-left: -6vw;
    color: white;
  }

  .icon{
    margin-bottom: 2px;
  }

  .liContact{
    font-size: 5vw;
    list-style-type: none;
    padding: auto 0 !important;
  }

  .liContact::before {
    content: "•";
    margin-right: 3vw;
    font-size: 7vw;
    color: #909800;
    width: 3vw;
  }

}
