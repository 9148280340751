.wrapper{
  display: flex !important;
  justify-content: center !important;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  height: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: scroll;
}

.cvButton{
  padding: 5px 10px;
  margin: 20px 0;
  border: .5px solid #909800;
  background: transparent;
  color: white;
  transition: all 1s ease-in-out;
}

.cvButton:hover{
 opacity: .8;
 color: #909800;
}

.cvButton:focus{
  outline: none;
 }

.wrapperUp{
  animation-name: goUP;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  transition: all 1s;
}

.pContainer{
  display: flex;
  justify-content: center;
  padding-right: 1vw;
}

::-webkit-scrollbar {
  width: 7px !important;
}

::-webkit-scrollbar-corner {
  background: rgba(0,0,0,0) !important; }

::-webkit-scrollbar-thumb {
  background-color: rgba(144, 152, 0, .5) !important;
}


.contentWrapper{
  margin-top: 5vw;
  display: flex;
  padding: 0 15vw;
  justify-content: center;
  align-items: center;
}

.meImg{
  border-radius: 50%;
  border: 5px solid #ccc;
  margin-bottom: 2vw;
  margin-left: 3vw;
}

@keyframes goUP{
  0%{height: 0;}
  100%{height: 100%;}
}

.wrapperAboutMe{
   display: flex;
   flex-direction: column;
   align-items: center;
   z-index: 999;
   opacity: 1;
}

/*CSSTransition*/

/*end CSSTransition*/


@keyframes makeImg{
  0%{width: 0; height: 0}
  100%{width: 25vw; height: 26vw}
}

.showImg{
  animation: makeImg;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  transition: all 1s;
}


h2{
  color: white;
  margin-top: 3vw !important;
  color: #909800;
}

p{
  width: 60%;
  color: white;
}

.hrAboutMe{
  height: 0.1px !important;
  width: 40%;
  background-color: #ccc;
}

.link{
  color: #909800;
}




@media screen and (max-width: 500px){
 
  .contentWrapper{
    margin-top: 2vw;
    display: flex !important;
    padding: 0vw !important;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

  }

  .pContainer p{
    margin-top: 8vw;
    height: 130vw;
    width: 85%;
  }

  .meImg{
    margin-top: 5vw;
    border-radius: 50%;
    border: 3px solid #ccc;
    margin-bottom: 0;
    width: 45vw;
    height: 47vw;
    margin-left: 0vw;
  }

  .hrAboutMe{
    width: 80%;
  }


  @keyframes makeImg{
    0%{width: 0; height: 0}
    100%{width: 45vw; height: 47vw}
  }

  .showImg{
    animation: makeImg;
    animation-duration: .5s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    transition: all 1s;
  }

}
