.wholeWrapperCv{
    height: 500px;
    overflow: scroll;
}

.blockWrapper{
    display: flex;
    margin: 0px 0;
    width: 90%;
    margin-top: 3px;
}


.titelCV{
    color: #909800;
    font-size: 18px;
    border-bottom: 1.5px solid #909800;
    padding-bottom: 5px;
    margin: 25px 0;
    width: 80%;
}


.dateArrowWrapper{
  display: flex;
  width: 200px;
}

.arrowCV{
    font-size: 20px;
    color: #909800;
    margin-top: -5px !important;   
}

.date{
    font-size: 14px;
    color: black;
}

.descWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.titelDesc{
    font-weight: bold;
    font-size: 14px;
    color: black;
    margin-bottom: 5px;
}

.desc{
    font-size: 14px;
    color: black;
}

@media (max-width: 800px){
    .blockWrapper{
        display: block;
        width: 500px;
    }

    .wholeWrapperCv{
        height: 100%;
        overflow-x: hidden;
    }

    .dateArrowWrapper{
        width: 200px;
      }

      .descWrapper{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-left: 20px;
    }

}
