.wrapper{
  display: flex !important;
  justify-content: center !important;
  background: rgba(0, 0, 0, 0.8);
  position: absolute;
  height:100vh !important;
  width: 100%;
  z-index: -1;
  overflow: scroll;
}

.wrapperUp{
  animation-name: goUP;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  transition: all 1s;
}

@keyframes goUP{
  0%{height: 0;}
  100%{height: 100%;}
}

.iconSkills{
  margin-bottom: 1px;
  font-size: 2vw;
  color: #909800;
}

.icons{
  margin-right: 3px;
}

.react{
  margin-right: 3px;
}

.wrapperKompotenzen{
   display: flex;
   flex-direction: column;
   align-items: center;
   height:100vh !important;
}

/*CSSTransition*/

/*end CSSTransition*/


.progressWrapper{
  width: 50vw;
  margin: 3vw 0;
}

.wrapperSkills{
  margin-top: 3vw;
  width: 50vw;
  margin-bottom: 2vw;
}

.pProgress{
margin: 0 1vw;
}


.progressState{
  height: 2vw;
  background: #909800 !important;
}

h2{
  color: white;
  margin-top: 3vw !important;
  color: #909800;
}

p{
  width: 60%;
  color: white;
}

.hrKompotenzen{
  margin: 1vw auto !important;
  height: 0.01px;
  width: 50vw !important;
  background-color: #ccc;
}

.link{
  color: #909800;
}


.progressColor{
  color: #909800 !important;
}


.progress   {background: rgba(204, 204, 204, 1) !important;
            border: 0px solid rgba(144, 152, 0, 1) !important;
            border-radius: 4px; height: 20px; }
.progress-bar-custom {background: rgba(144, 152, 0, 1) !important;}



@media screen and (max-width: 470px){
  .progressWrapper{
     margin-top: 7vw;
     margin-left: 3vw;
     display: flex;
     flex-direction: column;
     align-items: center;
     width: 90vw;
  }

  .progressState{
    height: 5vw !important;
  }

  .iconSkills{
    margin-bottom: 1px;
    font-size: 5vw;
    color: #909800;
  }

  .wrapperSkills{
    margin-bottom: 5vw;
    width: 70vw;
  }

}
