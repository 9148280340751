body{
  background-image: url("../images/bg.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  margin:0;
  padding: 0;
}

@media screen and (max-width: 470px){

   body{
     background-image: url("../images/bg.jpg");
     background-size: cover;
     background-repeat: no-repeat;
     margin:0;
     padding: 0;

   }
}
